// *******************************************************************************
// Close Modal When Escape Key Press
// *******************************************************************************

document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') {
    const modalElem = document.querySelectorAll('.modal');
    if (modalElem.length > 0) {
      modalElem.forEach((el) => {
        el.classList.remove('is-open-modal');
        toggleBodyScrollLock(false);
      });
    }
  }
});

attachEvent('.modal__close, .modal__bg', 'click', (ev) => {
  const parentSectionEle = ev.target.closest('section');
  const parentModal = ev.target.closest('.modal');
  parentModal.classList.remove('is-open-modal');
  toggleBodyScrollLock(false);

  if (parentSectionEle) {
    parentSectionEle.classList.remove('is-open-table-modal');
  }

  ev.preventDefault();
});
