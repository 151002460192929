// *******************************************************************************
// Form Module
// *******************************************************************************

const projectEle = document.querySelector('.form-module');

if (projectEle) {
  const contentEle = projectEle.querySelector('.form-module__top');
  const imageEle = projectEle.querySelector('.form-module__bg-image');

  const setImageHeight = () => {
    const contentEleHeight = contentEle.clientHeight;
    imageEle.style.setProperty('--bg-height', `${contentEleHeight}px`);
  };

  window.addEventListener('load', () => {
    setImageHeight();
  });

  window.addEventListener('resize', () => {
    setImageHeight();
  });
}
