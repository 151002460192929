// *******************************************************************************
// Form Tabs
// *******************************************************************************

const removeFormTabActiveClass = (parentElem) => {
  const navLinks = parentElem.querySelectorAll('.form-tabs__tab-btn.is-active');
  const tabPanels = parentElem.querySelectorAll('.form-tabs__tabpanel.is-active');
  const activeItems = [...navLinks, ...tabPanels];
  activeItems.forEach((elem) => {
    elem.classList.remove('is-active');
  });
};

attachEvent('.form-tabs__tab-btn', 'click', (ev) => {
  const button = ev.target;
  const parentElem = button.closest('.form-tabs');
  const tabId = button.getAttribute('aria-controls');
  const panelElement = parentElem.querySelector(`#${tabId}`);
  removeFormTabActiveClass(parentElem);
  button.classList.add('is-active');
  panelElement.classList.add('is-active');
  ev.preventDefault();
});
