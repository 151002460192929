// *******************************************************************************
// 1.0 - Mobile menu toggle
// *******************************************************************************

attachEvent('.mobile-hamburger', 'click', (ev) => {
  ev.preventDefault();

  // Open menu
  document.body.classList.toggle('is-open-menu');
  const hamburger = ev.target.closest('.mobile-hamburger');

  const isOpen = hamburger.classList.contains('is-open');

  hamburger.classList.toggle('is-open', !isOpen);
  hamburger.setAttribute('aria-expanded', !isOpen ? 'true' : 'false');
});

// *******************************************************************************
// 2.0 - Add class to body after some scroll
// *******************************************************************************
function addScrollingClass() {
  const topOffset = document.documentElement.scrollTop || document.body.scrollTop;
  document.body.classList.toggle('is-scrolled', topOffset > 20);
}

let ticking = false;
document.addEventListener('scroll', () => {
  if (!ticking) {
    window.requestAnimationFrame(() => {
      addScrollingClass();
      ticking = false;
    });
    ticking = true;
  }
});
