// **********************************************************
// Accordion
// **********************************************************

attachEvent('.accordion__title', 'click', (ev) => {
  const parentElem = ev.target.closest('.accordion');
  const accordionContent = parentElem.querySelector('.accordion__content');
  parentElem.classList.toggle('is-open');
  slideToggle(accordionContent, 300, 'block');
});
