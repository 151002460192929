// *******************************************************************************
// 1.0 - Open dropdown
// *******************************************************************************

const dropdownElement = document.querySelectorAll('.dropdown');
const dropdownPlaceholderElement = document.querySelectorAll('.dropdown__placeholder');

dropdownPlaceholderElement.forEach((el) => {
  el.addEventListener('click', () => {
    const dropdownParent = el.closest('.dropdown');

    dropdownElement.forEach((elem) => {
      if (elem === dropdownParent) {
        // Is this the dropdown we clicked on? Just toggle the class
        elem.classList.toggle('is-open');
      } else {
        // Remove class from all other dropdowns on page
        elem.classList.remove('is-open');
      }
    });
  });
});

// *******************************************************************************
// 2.0 - Click on dropdown option
// *******************************************************************************

const dropdownOptionElement = document.querySelectorAll('.dropdown__list-option');
const getHash = () => window.location.hash.replace(/^#/, '');

dropdownOptionElement.forEach((el) => {
  el.addEventListener('click', (ev) => {
    const dropdownParent = el.closest('.dropdown');
    const dropdownPlaceholderText = dropdownParent.querySelectorAll('.dropdown__placeholder-text');

    if (!dropdownParent.classList.contains('dropdown--multiselect')) {
      // Change text
      const dropdownOptionText = ev.target.innerHTML;
      dropdownPlaceholderText[0].innerHTML = dropdownOptionText;
    }

    // Change location hash if it already exists
    const locationHash = getHash();
    if (locationHash) {
      const optionValue = ev.target.parentElement.querySelector('input').getAttribute('id');
      window.location.hash = `#${optionValue}`;
    }

    // Close the dropdown
    if (!dropdownParent.classList.contains('dropdown--multiselect')) {
      dropdownParent.classList.remove('is-open');
    }
    dropdownParent.classList.add('is-selected');
  });
});

// *******************************************************************************
// 3.0 - Open a specific dropdown based on has in url
// *******************************************************************************

document.addEventListener('DOMContentLoaded', () => {
  const locationHash = getHash();

  if (locationHash) {
    const selectedElem = document.querySelectorAll(`#${locationHash}`);

    // Loop throught elements to avoid errors if there is no element to be found
    selectedElem.forEach((el) => {
      // Set active selection
      el.setAttribute('checked', 'true');

      // Change text
      const dropdownParent = el.closest('.dropdown');
      if (dropdownParent) {
        const optionLabel = el.parentElement.querySelector('.dropdown__list-option');
        const dropdownPlaceholder = dropdownParent.querySelectorAll('.dropdown__placeholder');
        const dropdownOptionText = optionLabel.innerHTML;
        dropdownPlaceholder[0].innerHTML = dropdownOptionText;
      }
    });
  }
});

document.addEventListener('click', (evt) => {
  const dropdownElemOpened = document.querySelector('.dropdown.is-open');
  let targetElement = evt.target; // clicked element

  do {
    if (targetElement === dropdownElemOpened) {
      return;
    }
    targetElement = targetElement.parentNode;
  } while (targetElement);

  dropdownElement.forEach((el) => {
    const dropdownParent = el.closest('.dropdown');
    dropdownParent.classList.remove('is-open');
  });
});
