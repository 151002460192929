/* global Cookies, attachEvent */

const cookieElement = document.querySelector('.cookie-banner');
const loadScripts = () => {
  const scripts = document.querySelectorAll('.sp-cookies.gtm');
  scripts.forEach((script) => {
    // eslint-disable-next-line no-param-reassign
    script.type = 'text/javascript';
    document.head.appendChild(script);
  });
};

const toggleCookieClass = (toggle) => {
  loadScripts();
  cookieElement.classList.toggle('cookie-not-set', toggle);
};

if (cookieElement) {
  if (!Cookies.get('cookie-banner-seen')) {
    cookieElement.classList.add('cookie-not-set');
  }

  attachEvent('.cookie-accept', 'click', (ev) => {
    Cookies.set('cookie-banner-seen', 'cookie-set', { expires: 7 });
    toggleCookieClass(false);
    ev.preventDefault();
  });

  attachEvent('.cookie-decline', 'click', (ev) => {
    Cookies.remove('cookie-banner-seen', 'cookie-set', { expires: 7 });
    toggleCookieClass(false);
    ev.preventDefault();
  });
}

if (!cookieElement) {
  loadScripts();
}
