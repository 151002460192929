// *******************************************************************************
// Open Card Modal
// *******************************************************************************

const toggleModal = (element, toggle) => {
  element.classList.toggle('is-open-modal', toggle);
};

const handleForm = async (formParent) => {
  const form = formParent.querySelector('form');
  const formModule = formParent.closest('.form-module');
  const transportForm = formParent.querySelectorAll('[data-transport-form]');
  const confirmationForm = formParent.querySelectorAll('[data-confirmation-form]');
  const formAction = form.querySelector('[data-form-action]');
  // const nonce = await getNonceForAction(formAction);

  // const axiosAction = (formData) => {
  //   grecaptcha.ready(() => {
  //     grecaptcha.execute(localData.captchaSiteKey, { action: formAction }).then((token) => {
  //       const formErrorMessage = form.querySelector('.form-error-message');
  //       formErrorMessage.classList.remove('is-validation-failed');
  //       const formDataInput = new FormData();
  //       formDataInput.append('action', formAction);
  //       formDataInput.append('nonce', nonce);
  //       formDataInput.append('inputs', JSON.stringify(formData));
  //       formDataInput.append('lang', localData.lang);
  //       formDataInput.append('id', localData.id);
  //       formDataInput.append('token', token);
  //       axios
  //         .post(localData.apiURL, formDataInput, {
  //           headers: {
  //             'Content-Type': 'multipart/form-data',
  //           },
  //         })
  //         .then((response) => {
  //           if (response.status === 200) {
  //             window.location.replace(response.data.data.url);
  //           }
  //           if (response.status !== 200) {
  //             if (response.response.data.data.msg) {
  //               formErrorMessage.innerHTML = response.response.data.data.msg;
  //             }
  //             formErrorMessage.classList.add('is-validation-failed');
  //           }
  //         })
  //         .catch((err) => {
  //           if (err.response.data.data.msg) {
  //             formErrorMessage.innerHTML = err.response.data.data.msg;
  //           }
  //           formErrorMessage.classList.add('is-validation-failed');
  //         });
  //     });
  //   });
  // };

  const fv = FormValidation.formValidation(form, {
    locale: 'sl_SI',
    localization: FormValidation.locales.sl_SI,
    fields: {
      registerPassword: {
        validators: {
          regexp: {
            regexp: /(?=.{8,})/g,
          },
        },
      },
      resetPassword: {
        validators: {
          regexp: {
            regexp: /(?=.{8,})/g,
          },
        },
      },
      confirmResetPassword: {
        validators: {
          identical: {
            compare() {
              return formEle.querySelector('[name="resetPassword"]').value;
            },
          },
        },
      },
    },
    plugins: {
      message: new FormValidation.plugins.Message({
        container: (field, element) => element.closest('.mod-input,.checkbox'),
      }),
      declarative: new FormValidation.plugins.Declarative({
        html5Input: true,
      }),
      trigger: new FormValidation.plugins.Trigger(),
      submitButton: new FormValidation.plugins.SubmitButton(),
    },
  })
    .on('core.element.validated', (e) => {
      // e.element presents the field element
      // e.valid indicates the field is valid or not

      // Get the field value
      const value = fv.getElementValue(e.field, e.element);
      const container = FormValidation.utils.closest(e.element, '.mod-input,.checkbox');
      FormValidation.utils.classSet(container, {
        'is-validation-failed': value === '' || !e.valid,
      });
    })
    .on('core.form.valid', () => {
      // const formData = {};
      // form.querySelectorAll('input, textarea').forEach((input) => {
      //   if (input.type === 'checkbox') {
      //     formData[input.name] = input.checked;
      //   } else {
      //     formData[input.name] = input.value.trim();
      //   }
      // });
      // axiosAction(formData);
      if (transportForm.length > 0) {
        const transportFormModal = formModule.querySelector('.card-modal');
        toggleModal(transportFormModal, true);
        toggleBodyScrollLock(true);
      }

      if (confirmationForm.length > 0) {
        const shareDataModal = document.querySelector('.share-card-modal');
        toggleModal(formParent, false);
        toggleModal(shareDataModal, true);
        toggleBodyScrollLock(true);
      }
    });
};

// *******************************************************************************
// Transport form validation
// *******************************************************************************

const transportFormsElem = document.querySelectorAll('.transport-form');

if (transportFormsElem.length > 0) {
  transportFormsElem.forEach((element) => {
    handleForm(element);
  });
}

// *******************************************************************************
// Confirmation Modal Validation
// *******************************************************************************

const cardListModal = document.querySelectorAll('.confirmation-modal-form');

if (cardListModal.length > 0) {
  cardListModal.forEach((element) => {
    const parentElem = element.closest('.modal');
    handleForm(parentElem);
  });
}
