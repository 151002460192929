/* eslint no-param-reassign: "error" */
/* eslint-disable no-new */

// *******************************************************************************
// 0.1 - BREAKPOINT VARS
// *******************************************************************************
const breakpointSm = 767;
const breakpointMd = 1023;
const breakpointLg = 1171;

// *******************************************************************************
// 0.2 - VARIOUS FUNCTIONS
// *******************************************************************************

// Return Window Width
function windowWidth() {
  return window.innerWidth || document.documentElement.clientWidth;
}

// *******************************************************************************
// 0.3 - FUNCTIONS
// *******************************************************************************

// Attach Events
function attachEvent(selector, event, handler) {
  document.addEventListener(
    event,
    (ev) => {
      let { target } = ev;
      for (; target && target !== document; target = target.parentNode) {
        if (target.matches(selector)) {
          try {
            handler.call(target, ev);
          } catch (e) {
            console.error(e);
          }
          break;
        }
      }
    },
    false,
  );
}

// Animate
function animate(elementToAnimate, type) {
  switch (type) {
    case 'show':
      elementToAnimate.classList.add('is-visible');
      elementToAnimate.classList.remove('is-hidden');
      break;
    case 'hide':
      elementToAnimate.classList.add('is-hidden');
      elementToAnimate.classList.remove('is-visible');
      break;
    default:
      break;
  }
}

// Validate email input
const validateEmailInput = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// *******************************************************************************
// 0.4 - DEBOUNCE FUNCTION
// *******************************************************************************

function debounce(func, wait, immediate) {
  let timeout;
  return function (...args) {
    const context = this;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

// *******************************************************************************
// 0.5 - SLIDE FUNCTIONS
// *******************************************************************************

const slideUp = (target, duration) => {
  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = `${duration}ms`;
  target.style.boxSizing = 'border-box';
  target.style.height = `${target.offsetHeight}px`;
  // eslint-disable-next-line no-unused-expressions
  target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  window.setTimeout(() => {
    target.style.display = 'none';
    target.style.removeProperty('height');
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
  }, duration);
};

const slideDown = (target, duration, displayType) => {
  target.style.removeProperty('display');

  let { display } = window.getComputedStyle(target);

  // eslint-disable-next-line no-unused-expressions
  display === 'none' ? (display = displayType) : null;
  target.style.display = display;

  target.style.display = display;
  const height = target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  // eslint-disable-next-line no-unused-expressions
  target.offsetHeight;
  target.style.boxSizing = 'border-box';
  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = `${duration}ms`;
  target.style.height = `${height}px`;
  target.style.removeProperty('padding-top');
  target.style.removeProperty('padding-bottom');
  target.style.removeProperty('margin-top');
  target.style.removeProperty('margin-bottom');
  window.setTimeout(() => {
    target.style.removeProperty('height');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
  }, duration);
};

const slideToggle = (target, duration, displayType) => {
  /* Slide-toggle logic */

  if (window.getComputedStyle(target).display === 'none') {
    return slideDown(target, duration, displayType);
    // eslint-disable-next-line no-else-return
  } else {
    return slideUp(target, duration, displayType);
  }
};

// *******************************************************************************
// 0.6 - Calculate Viewport units
// *******************************************************************************

function calcVh() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

const debounceCalcVh = debounce(() => {
  calcVh();
}, 50);

// Only mobile has VH calculation
window.addEventListener('DOMContentLoaded', () => {
  calcVh();
});

// Only Calc VH for desktop, not mobile
window.addEventListener('resize', (event) => {
  if (
    !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) &&
    windowWidth() > breakpointSm
  ) {
    debounceCalcVh();
  }
});

// *******************************************************************************
// 0.7 - Smooth Scroll
// *******************************************************************************

// eslint-disable-next-line new-cap
const smoothScroll = new scrollToSmooth('a', {
  targetAttribute: 'data-scrollto',
  duration: 1000,
  durationRelative: false,
  durationMin: false,
  durationMax: false,
  easing: easeInOutCubic,
  onScrollStart: (data) => {
    // do something
  },
  onScrollUpdate: (data) => {
    // do something
  },
  onScrollEnd: (data) => {
    // do something
  },
  offset: '#header-main',
});
smoothScroll.init();

// function scrollToSmoothly(pos, time = 500) {
//   const currentPos = window.pageYOffset;
//   let start = null;

//   const step = (currentTime) => {
//     start = !start ? currentTime : start;
//     const progress = currentTime - start;
//     const positionUpY = currentPos - ((currentPos - pos) * progress) / time;
//     const positionDownY = ((pos - currentPos) * progress) / time + currentPos;

//     window.scrollTo(0, currentPos < pos ? positionDownY : positionUpY);

//     if (progress < time) {
//       window.requestAnimationFrame(step);
//     } else {
//       window.scrollTo(0, pos);
//     }
//   };

//   window.requestAnimationFrame(step);
// }

// *******************************************************************************
// 0.8 - Lazy loading (https://github.com/verlok/vanilla-lazyload)
// *******************************************************************************
// const lazyLoadInstance = new LazyLoad({
//   elements_selector: '.lazyload',
// });

// This can be called whenever you need to update the lazyload instance
// lazyLoadInstance.update();

// *******************************************************************************
// 0.9 - Match Height / Equal height : https://dev.to/dhruvangg/equal-height-in-vanilla-javascript-49ch
// *******************************************************************************

function setHeight(el, val) {
  // eslint-disable-next-line no-param-reassign
  if (typeof val === 'function') val = val();
  // eslint-disable-next-line no-param-reassign
  if (typeof val === 'string') el.style.height = val;
  // eslint-disable-next-line no-param-reassign
  else el.style.height = `${val}px`;
}

const equalheight = function (container) {
  let currentTallest = 0;
  let currentRowStart = 0;
  // eslint-disable-next-line no-array-constructor
  const rowDivs = new Array();
  let $el;
  const topPosition = 0;

  Array.from(document.querySelectorAll(container)).forEach((el, i) => {
    // eslint-disable-next-line no-param-reassign
    el.style.height = 'auto';
    const topPostion = el.offsetTop;
    if (currentRowStart !== topPostion) {
      let currentDiv = 0;

      // eslint-disable-next-line no-plusplus
      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
        setHeight(rowDivs[currentDiv], currentTallest);
      }
      rowDivs.length = 0;
      currentRowStart = topPostion;
      currentTallest = parseFloat(getComputedStyle(el, null).height.replace('px', ''));
      rowDivs.push(el);
    } else {
      rowDivs.push(el);
      // eslint-disable-next-line operator-linebreak
      currentTallest =
        currentTallest < parseFloat(getComputedStyle(el, null).height.replace('px', ''))
          ? parseFloat(getComputedStyle(el, null).height.replace('px', ''))
          : currentTallest;
    }

    let currentDiv = 0;
    // eslint-disable-next-line no-plusplus
    for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
      setHeight(rowDivs[currentDiv], currentTallest);
    }
  });
};

window.addEventListener('load', () => {
  equalheight('.equal-height');
});
window.addEventListener('resize', () => {
  setTimeout(() => {
    equalheight('.equal-height');
  });
});

// *******************************************************************************
// 0.10 - ENVIRONMENT BADGE
// *******************************************************************************
const envBadge = document.querySelector('.sdt-env__badge');
if (envBadge) {
  envBadge.style.top = '150px';
  envBadge.addEventListener('mouseover', (ev) => {
    const { style } = ev.target;
    if (style.top) {
      style.removeProperty('top');
      style.bottom = '250px';
    } else {
      style.removeProperty('bottom');
      style.top = '150px';
    }
  });
}

// *******************************************************************************
// 0.11 - Get nonce for static pages
// *******************************************************************************
const getNonceForAction = async (formAction) => {
  const formDataNonce = new FormData();
  formDataNonce.append('action', 'getNonceField');
  formDataNonce.append('nonce_action', formAction);
  return (
    axios
      .post(localData.apiURL, formDataNonce, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.status === 200) {
          // eslint-disable-next-line no-param-reassign
          return response.data.data.nonce;
        }
      })
      .catch((error) => {
        console.error(error);
      })
  );
};

// *******************************************************************************
// 0.12 - Get nonce for static pages
// *******************************************************************************
const getScrollbarWidth = () => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);
  const inner = document.createElement('div');
  outer.appendChild(inner);
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
  outer.parentNode.removeChild(outer);
  document.documentElement.style.setProperty('--scroll-width', `${scrollbarWidth}px`);
};

getScrollbarWidth();

window.addEventListener('resize', () => {
  getScrollbarWidth();
});

const toggleBodyScrollLock = (toggle) => {
  document.body.classList.toggle('body-scroll-locked', toggle);
};

// *******************************************************************************
// 0.12 - FancyBox Scrollbar
// *******************************************************************************

Fancybox.bind('[data-fancybox]', {
  hideScrollbar: false,
});

