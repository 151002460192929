// **************************************************
// Open modal
// **************************************************

attachEvent('.open-confirmation-modal', 'click', (ev) => {
  ev.preventDefault();
  const parentEle = ev.target.closest('section');
  const modal = parentEle.querySelector('.confirmation-modal');
  modal.classList.add('is-open-modal');
  toggleBodyScrollLock(true);
});
